import React from 'react'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'
import { useStore } from 'hooks'
import { BrandsStore } from 'stores/brands'
import { THEME } from 'utils'
import SidebarWidget from 'components/SidebarWidget'
import AboutLink from 'components/AboutLink'

const SidebarAbout = () => {
  const router = useRouter()
  const { id, slug } = router.query
  const brandsStore = useStore(BrandsStore)
  const brand = brandsStore.current
  const active_talent_brands = brand?.talent_brands?.filter((talent) => talent.status === 'active')

  const hasTalentBrands = active_talent_brands && active_talent_brands.length > 0
  return (
    <div className='sidebarAbout'>
      <SidebarWidget className={cn({ isEmpty: !hasTalentBrands })}>
        {hasTalentBrands && <h3>Hosts</h3>}
        {hasTalentBrands &&
          active_talent_brands?.map((author) => (
            <div className='author' key={author.name}>
              <img className='avatar' src={author.logo} alt={author.name} />
              <div className='name'>{author.name}</div>
            </div>
          ))}
        <AboutLink
          href='/shows/[id]/[slug]/about'
          as={`/shows/${id}/${slug}/about`}
          className={cn({ isEmpty: !hasTalentBrands })}
        >
          About The Show
        </AboutLink>
      </SidebarWidget>
      <style jsx>{`
        .sidebarAbout {
          display: none;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .sidebarAbout {
            display: block;
          }
        }
        h3 {
          margin-top: 0;
          margin-bottom: 24px;
          padding-bottom: 14px;
          border-bottom: 1px solid ${THEME.COLORS.LIGHTGRAY};
        }
        .author {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
        }
        .avatar {
          height: 48px;
          width: 48px;
          border-radius: 50%;
          margin-right: 16px;
        }
        .name {
          font-weight: bold;
          font-size: 16px;
        }
      `}</style>
    </div>
  )
}

export default observer(SidebarAbout)
